<template>
  <div class="py-3 relative">
    <gl-loader
      v-show="loading"
      class="alert-modal-loader"
    />
    <div
      class="mb-3"
      :class="{ 'short-large-grid': isCreate }"
    >
      <div
        v-show="!isCreate && !loading"
        class="link"
        @click="openAddressesListModal = true"
      >
        {{ formData.address && formData.address.length }} {{ formData.address && formData.address.length === 1 ? 'address' : 'addresses' }}
      </div>
      <div v-show="isCreate">
        <GlCoinSelect
          dark
          @change="handleCoinChange"
        />
      </div>
      <div v-show="isCreate">
        <div
          class="mb-2 flex mb-4"
        >
          <gl-menu-item
            v-if="!file"
            class="fs-14 pt-2"
            icon="import"
            :icon-height="24"
            :icon-width="24"
            label="Upload CSV"
            @click="openFileInput"
          />
          <div
            v-else
            class="pt-2 flex align-center"
          >
            <gl-menu-item
              class="fs-14"
              :disabled="loading"
              icon="file"
              :icon-height="24"
              :icon-width="24"
              :label="file.name || ''"
            />
            <gl-menu-item
              v-if="file"
              :disabled="loading"
              icon="delete-action"
              :icon-height="24"
              :icon-width="24"
              @click="clearFile"
            />
          </div>
          <input
            ref="addressAlertingUploader"
            accept=".csv"
            :style="{display: 'none'}"
            type="file"
            @input="onFileChange"
          >
        </div>
      </div>
    </div>
    <div :class="{ 'alert-modal-loading': loading }">
      <div class="short-large-grid mb-4">
        <div>
          <div class="alert-key">
            Direction
          </div>
          <vSelect
            v-model="formData.direction"
            :clearable="false"
            :disabled="!isCreate"
            :filterable="false"
            :options="alertDirection"
            :searchable="false"
            taggable
          />
        </div>
        <div>
          <div class="alert-key">
            Logic Operator
          </div>
          <vSelect
            v-model="formData.logicOperator"
            :clearable="false"
            disabled
            :filterable="false"
            :options="alertLogicOperators"
            :searchable="false"
          />
        </div>
      </div>
      <div class="alert-section-title mb-2">
        Triggers
      </div>
      <div
        v-for="(trigger, index) in formData.triggersList"
        :key="index"
        class="alert-section-list__item mb-2"
        :class="[{ 'mb-4': index + 1 === formData.triggersList.length}]"
      >
        <div>
          <div
            v-if="formData.logicOperator.label && index === 0"
            class="alert-section-list__trigger-item"
          >
            {{ formData.logicOperator.label }}
          </div>
        </div>
        <div
          class="alert-section-list__trigger-item pointer"
          :class="[{'alert-section-list__trigger-item--edit': trigger.edit}]"
        >
          <div>
            Trigger {{ index + 1 }} : Alert will be triggered if the address(es) {{ trigger.trigger.label.toLowerCase() }} from
            {{ formData.direction.label.toLowerCase() }}
          </div>
          <div class="flex align-baseline">
            <gl-icon
              v-popover:tooltip.top="'Edit'"
              class="mr-3 pointer"
              :height="24"
              name="edit-block-active"
              :width="24"
              @click="handleShortEdit(trigger, index)"
            />
            <gl-icon
              v-if="isCreate"
              v-popover:tooltip.top="'Delete'"
              class="pointer"
              fullwidth
              :icon-height="24"
              :icon-width="24"
              name="icons-close"
              @click="handleShortDelete(index)"
            />
          </div>
        </div>
        <div />
        <div
          v-if="trigger.edit"
          class="mt-2"
        >
          <div class="medium-grid">
            <div>
              <div class="alert-key">
                Trigger {{ index + 1 }}
              </div>
              <vSelect
                v-model="trigger.trigger"
                :clearable="false"
                :disabled="!isCreate"
                :filterable="false"
                :label="trackByLabel"
                :options="alertsTriggersList"
                placeholder="Choose Trigger Type"
                :searchable="false"
              />
            </div>
            <div v-if="trigger.trigger.value === 'address'">
              <div class="alert-key">
                Amount
              </div>
              <div class="flex align-baseline fullwidth">
                <vSelect
                  v-model="trigger.percentOfTxs"
                  class="alert-select-more mr-2 max-content"
                  :clearable="false"
                  :disabled="!isCreate"
                  :filterable="false"
                  :options="percentOfTxs"
                  :searchable="false"
                />
                <gl-input
                  v-model="trigger.amount"
                  class="gl-modal__row mr-2"
                  :custom-step="0.00000001"
                  :disabled="!isCreate"
                  :height="40"
                  name="score"
                  placeholder="Amount"
                  type="number"
                />
                <!--              <vSelect-->
                <!--                v-model="formData.triggerModel.quantityProp"-->
                <!--                class="alert-select-more mr-2"-->
                <!--                :clearable="false"-->
                <!--                disabled-->
                <!--                :filterable="false"-->
                <!--                :options="quantityPropsList"-->
                <!--                :searchable="false"-->
                <!--              />-->
              </div>
            </div>
            <div v-if="trigger.trigger.value === 'amountCur'">
              <div class="alert-key">
                Amount
              </div>
              <div class="flex align-baseline fullwidth">
                <vSelect
                  v-model="trigger.percentOfTxs"
                  class="alert-select-more mr-2 max-content"
                  :clearable="false"
                  :disabled="!isCreate"
                  :filterable="false"
                  :options="percentOfTxs"
                  :searchable="false"
                />
                <gl-input
                  v-model="trigger.amountCur"
                  class="gl-modal__row mr-2"
                  :disabled="!isCreate"
                  :height="40"
                  name="score"
                  placeholder="Amount"
                  type="number"
                />
              </div>
            </div>
            <div v-else>
              <div v-if="trigger.trigger.value === 'owner'">
                <div class="mb-4">
                  <div class="alert-key">
                    Owners List
                  </div>
                  <vSelect
                    v-model="trigger.owner"
                    append-to-body
                    :autoscroll="false"
                    class="mb-2 tagged"
                    :class="{'clear-disable': trigger.owner && trigger.owner.length === 1}"
                    :disabled="!isCreate"
                    label="owner"
                    multiple
                    :options="ownersList"
                    placeholder="Choose owners"
                    @search="searchByOwner"
                  />
                </div>
              </div>
              <div
                v-if="trigger.trigger.value === 'funds.id'"
                class="mb-3"
              >
                <div class="alert-key">
                  Tags & Types
                </div>
                <vSelect
                  v-model="trigger.types"
                  append-to-body
                  :autoscroll="false"
                  class="mb-2 tag-view"
                  :class="{'clear-disable': trigger.types && trigger.types.length === 1 && trigger.tags && !trigger.tags.length}"
                  :close-on-select="false"
                  :disabled="!isCreate"
                  label="name"
                  multiple
                  :options="typesList"
                  placeholder="Choose types"
                >
                  <template #selected-option="{ name, score }">
                    <GlTag
                      :removable="isCreate"
                      :score="score"
                      :tag="name"
                    />
                  </template>
                  <template #option="{ name, score, _id }">
                    <GlCheckbox
                      class="mb-3"
                      :value="trigger.types && trigger.types.find(el => el._id === _id)"
                    />
                    <GlTag
                      :score="score"
                      :tag="name"
                    />
                  </template>
                </vSelect>
                <vSelect
                  v-model="trigger.tags"
                  append-to-body
                  :autoscroll="false"
                  class="tag-view"
                  :class="{'clear-disable': trigger.tags && trigger.tags.length === 1 && !trigger.types.length}"
                  :close-on-select="false"
                  :disabled="!isCreate"
                  label="name"
                  multiple
                  :options="tagsList"
                  placeholder="Choose tags"
                >
                  <template #selected-option="{ name, score }">
                    <GlTag
                      :removable="isCreate"
                      :score="score"
                      :tag="name"
                    />
                  </template>
                  <template #option="{ name, score, _id }">
                    <GlCheckbox
                      class="mb-3"
                      :value="trigger.tags && trigger.tags.find(el => el._id === _id)"
                    />
                    <GlTag
                      :score="score"
                      :tag="name"
                    />
                  </template>
                </vSelect>
              </div>
              <div v-if="trigger.trigger.value === 'funds.score'">
                <div class="alert-key">
                  Score
                </div>
                <div class="flex fullwidth">
                  <gl-input
                    v-model="trigger.scoreFrom"
                    v-mask="'###'"
                    class="gl-modal__row mr-2 fullwidth"
                    :disabled="!isCreate"
                    :height="40"
                    name="score"
                    placeholder="Score From"
                    :rules="`max_value:${trigger.scoreTo || 100}`"
                    type="number"
                  />
                  <gl-input
                    v-model="trigger.scoreTo"
                    v-mask="'###'"
                    class="gl-modal__row fullwidth"
                    :disabled="!isCreate"
                    :height="40"
                    name="score"
                    placeholder="Score To"
                    rules="max_value:100"
                    type="number"
                    @input="handleScoreTo(trigger.scoreTo, index)"
                  />
                </div>
              </div>
              <div class="bold mb-1">
                % of TX
                <gl-explain-block
                  class="ml-1"
                  :tooltip="shareInfo"
                />
              </div>
              <div class="flex">
                <vSelect
                  v-model="trigger.percentOfTxs"
                  class="alert-select-more mr-2 max-content"
                  :clearable="false"
                  :disabled="!isCreate"
                  :filterable="false"
                  :options="percentOfTxs"
                  :searchable="false"
                />
                <gl-input
                  v-model="trigger.share"
                  v-mask="'###'"
                  class="gl-modal__row"
                  :disabled="!isCreate"
                  :height="40"
                  name="score"
                  placeholder="0-100"
                  rules="max_value:100"
                  type="number"
                  @input="handleShare(trigger.share, index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isEdgeTriggersLength && isCreate">
        <div class="short-large-grid mb-2">
          <div>
            <div class="alert-key">
              Trigger {{ formData.triggersList.length + 1 }}
            </div>
            <vSelect
              v-model="formData.triggerModel.trigger"
              :clearable="false"
              :filterable="false"
              :label="trackByLabel"
              :options="alertsTriggersList"
              placeholder="Choose Trigger Type"
              :searchable="false"
            />
          </div>
          <div v-if="formData.triggerModel && formData.triggerModel.trigger && formData.triggerModel.trigger.value === 'address'">
            <div class="flex">
              <div class="fullwidth">
                <div class="alert-key">
                  Amount
                </div>
                <div class="flex mb-3 align-center">
                  <vSelect
                    v-model="formData.triggerModel.percentOfTxs"
                    class="alert-select-more mr-2 max-content"
                    :clearable="false"
                    :filterable="false"
                    :options="percentOfTxs"
                    :searchable="false"
                  />
                  <gl-input
                    v-model="formData.triggerModel.amount"
                    class="mr-2"
                    :custom-step="0.00000001"
                    :height="40"
                    name="score"
                    placeholder="Amount"
                    type="number"
                  />
                  <gl-menu-item
                    v-if="formData.triggersList.length < MAX_TRIGGERS"
                    :disabled="isEdgeTriggersLength || !formData.triggerModel.amount"
                    icon="add"
                    :icon-height="24"
                    :icon-width="24"
                    label="Add trigger"
                    @click="addTrigger"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="formData.triggerModel && formData.triggerModel.trigger && formData.triggerModel.trigger.value === 'amountCur'">
            <div class="flex">
              <div class="fullwidth">
                <div class="alert-key">
                  Amount
                </div>
                <div class="flex mb-3 align-center">
                  <vSelect
                    v-model="formData.triggerModel.percentOfTxs"
                    class="alert-select-more mr-2 max-content"
                    :clearable="false"
                    :filterable="false"
                    :options="percentOfTxs"
                    :searchable="false"
                  />
                  <gl-input
                    v-model="formData.triggerModel.amountCur"
                    class="mr-2"
                    :height="40"
                    name="score"
                    placeholder="Amount"
                    type="number"
                  />
                  <gl-menu-item
                    v-if="formData.triggersList.length < MAX_TRIGGERS"
                    :disabled="isEdgeTriggersLength || !formData.triggerModel.amountCur"
                    icon="add"
                    :icon-height="24"
                    :icon-width="24"
                    label="Add trigger"
                    @click="addTrigger"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="formData.triggerModel && formData.triggerModel.trigger && formData.triggerModel.trigger.value === 'funds.score'">
            <div class="alert-key">
              Score
            </div>
            <div class="flex fullwidth">
              <gl-input
                v-model="formData.triggerModel.scoreFrom"
                v-mask="'###'"
                class="gl-modal__row mr-2 fullwidth"
                :height="40"
                name="scoreFrom"
                placeholder="Score From"
                :rules="`max_value:${formData.triggerModel.scoreTo || 100}`"
                type="number"
              />
              <gl-input
                v-model="formData.triggerModel.scoreTo"
                v-mask="'###'"
                class="gl-modal__row fullwidth"
                :height="40"
                name="scoreTo"
                placeholder="Score To"
                rules="max_value:100"
                type="number"
                @input="handleScoreTo"
              />
            </div>
            <div class="bold mb-1">
              % of TX
              <gl-explain-block
                class="ml-1"
                :tooltip="shareInfo"
              />
            </div>
            <div class="flex">
              <vSelect
                v-model="formData.triggerModel.percentOfTxs"
                class="alert-select-more mr-2 max-content"
                :clearable="false"
                :filterable="false"
                :options="percentOfTxs"
                :searchable="false"
              />
              <gl-input
                v-model="formData.triggerModel.share"
                v-mask="'###'"
                class="gl-modal__row"
                :height="40"
                name="score"
                placeholder="0-100"
                rules="max_value:100"
                type="number"
                @input="handleShare"
              />
              <gl-menu-item
                class="mt-2"
                :disabled="!formData.triggerModel.scoreFrom || !formData.triggerModel.scoreTo || isEdgeTriggersLength || Number(formData.triggerModel.scoreFrom) > Number(formData.triggerModel.scoreTo)"
                icon="add"
                :icon-height="24"
                :icon-width="24"
                label="Add trigger"
                @click="addTrigger"
              />
            </div>
          </div>
        </div>
        <div v-if="isOwnerTrigger">
          <div class="large-short-grid mb-4">
            <div>
              <div class="alert-key">
                Owners List
              </div>
              <vSelect
                v-model="formData.triggerModel.owner"
                append-to-body
                :autoscroll="false"
                class="mb-2 tagged"
                label="owner"
                multiple
                :options="ownersList"
                placeholder="Choose owners (enter 3 or more characters)"
                @search="searchByOwner"
              />
            </div>
            <div>
              <div class="bold mb-1">
                % of TX
                <gl-explain-block
                  class="ml-1"
                  :tooltip="shareInfo"
                />
              </div>
              <div class="flex">
                <vSelect
                  v-model="formData.triggerModel.percentOfTxs"
                  class="alert-select-more mr-2"
                  :clearable="false"
                  :filterable="false"
                  :options="percentOfTxs"
                  :searchable="false"
                />
                <gl-input
                  v-model="formData.triggerModel.share"
                  v-mask="'###'"
                  class="gl-modal__row"
                  :height="40"
                  name="score"
                  placeholder="0-100"
                  rules="max_value:100"
                  type="number"
                  @input="handleShare"
                />
                <gl-menu-item
                  class="mt-2"
                  :disabled="!formData.triggerModel.owner.length || isEdgeTriggersLength"
                  icon="add"
                  :icon-height="24"
                  :icon-width="24"
                  label="Add trigger"
                  @click="addTrigger"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="isTypesTagsTrigger">
          <div class="large-short-grid mb-4">
            <div>
              <vSelect
                v-model="formData.triggerModel.types"
                append-to-body
                :autoscroll="false"
                class="mb-2 tag-view"
                :close-on-select="false"
                label="name"
                multiple
                :options="typesList"
                placeholder="Choose types"
              >
                <template #selected-option="{ name, score }">
                  <GlTag
                    :removable="isCreate"
                    :score="score"
                    :tag="name"
                  />
                </template>
                <template #option="{ name, score, _id }">
                  <GlCheckbox
                    class="mb-3"
                    :value="formData.triggerModel && formData.triggerModel.types.find(el => el._id === _id)"
                  />
                  <GlTag
                    :score="score"
                    :tag="name"
                  />
                </template>
              </vSelect>
              <vSelect
                v-model="formData.triggerModel.tags"
                append-to-body
                :autoscroll="false"
                class="tag-view"
                :close-on-select="false"
                label="name"
                multiple
                :options="tagsList"
                placeholder="Choose tags"
              >
                <template #selected-option="{ name, score }">
                  <GlTag
                    :removable="isCreate"
                    :score="score"
                    :tag="name"
                  />
                </template>
                <template #option="{ name, score, _id }">
                  <GlCheckbox
                    class="mb-3"
                    :value="formData.triggerModel && formData.triggerModel.tags.find(el => el._id === _id)"
                  />
                  <GlTag
                    :score="score"
                    :tag="name"
                  />
                </template>
              </vSelect>
            </div>
            <div>
              <div class="bold mb-1">
                % of TX
                <gl-explain-block
                  class="ml-1"
                  :tooltip="shareInfo"
                />
              </div>
              <div class="flex">
                <vSelect
                  v-model="formData.triggerModel.percentOfTxs"
                  class="alert-select-more mr-2 max-content"
                  :clearable="false"
                  :filterable="false"
                  :options="percentOfTxs"
                  :searchable="false"
                />
                <gl-input
                  v-model="formData.triggerModel.share"
                  v-mask="'###'"
                  class="gl-modal__row"
                  :height="40"
                  name="score"
                  placeholder="0-100"
                  rules="max_value:100"
                  type="number"
                  @input="handleShare"
                />
                <gl-menu-item
                  class="mt-2"
                  :disabled="!formData.triggerModel.tags.length && !formData.triggerModel.types.length || isEdgeTriggersLength"
                  icon="add"
                  :icon-height="24"
                  :icon-width="24"
                  label="Add trigger"
                  @click="addTrigger"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="alert-section-title mb-2">
        Priority
      </div>
      <div class="short-large-grid mb-4">
        <vSelect
          v-model="formData.priority"
          :clearable="false"
          label="label"
          :options="alertPriority"
          placeholder="Choose priority"
        >
          <template #selected-option="{ label, value }">
            <GlPriorityChip
              :label="label"
              :priority-level="value"
              rounded="l"
            />
          </template>
          <template #option="{ label, value }">
            <GlPriorityChip
              :label="label"
              :priority-level="value"
              rounded="l"
            />
          </template>
        </vSelect>
      </div>
      <div class="alert-section-title mb-2">
        Event name & description
      </div>
      <div class="short-large-grid mb-4">
        <GlInput
          v-model="formData.eventName"
          :height="40"
          :max-length="MAX_EVENT_NAME_LENGTH"
          placeholder="Event name"
          :rules="`max:${MAX_EVENT_NAME_LENGTH}`"
        />
        <GlInput
          v-model="formData.description"
          :height="40"
          :max-length="MAX_DESCRIPTION_LENGTH"
          placeholder="Description"
          :rules="`max:${MAX_DESCRIPTION_LENGTH}`"
        />
      </div>
      <div class="flex align-center">
        <gl-checkbox
          v-model="formData.retrospective"
          label="Create Profile flag"
          :value="formData.retrospective"
        />
        <gl-explain-block
          class="ml-1"
          :tooltip="retrospectiveInfo"
        />
      </div>
      <div class="mt-4">
        <div class="flex flex-end">
          <button
            class="gl-button gl-button--light gl-form__button gl-button--padder gl-button-bulk--large mr-2"
            type="reset"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="gl-button gl-button--dark gl-form__button gl-button--padder gl-button-bulk--large"
            :disabled="!canSend || loading"
            @click="handleSubmit"
          >
            {{ isCreate? 'Create Alert' : 'Update Alert' }}
          </button>
        </div>
      </div>
    </div>
    <AddressesListModal
      v-model="openAddressesListModal"
      :addresses="formData.address || []"
      @close="openAddressesListModal = false"
    />
    <uploadBulkResultModal
      v-model="openUploadedModal"
      :data="createReportData"
      @close="handleCloseUploadedModal"
      @submit="handleCloseUploadedModal"
    />
  </div>
</template>

<script>
// Components
import vSelect from "vue-select";
import GlIcon from "@/components/gl-icon.vue";
import GlInput from "@/components/gl-input.vue";
import GlMenuItem from "@/components/gl-menu-item.vue";
import GlCheckbox from "@/components/gl-checkbox.vue";
import GlCoinSelect from "@/components/gl-coin-select.vue";
import uploadBulkResultModal from "@/pages/alerting/modals/uploadBulkResultModal.vue";
// Static
import {
  percentOfTxs,
  alertLogicOperators,
  alertDirection,
  alertPriority, alertChanel, alertsTriggersList, quantityPropsList
} from "@/pages/statistics/static/filtersLabelsData";
// Vuex
import { mapState, mapActions } from "vuex";
// Utils
import { createAlertModel, createTriggerModel, createFilterModel, createConditionModel } from "@/utils/alerts"
import GlLoader from "@/components/gl-loader.vue";
import { currencyShareFormatting } from "@/utils/format-btc-amount";
// import analyticsState from "@/store/modules/analytics/state";
import GlPriorityChip from "@/components/gl-priority-chip.vue";
import GlExplainBlock from "@/components/gl-explain-block.vue";
import AddressesListModal from "@/pages/alerts/modals/AddressesListModal.vue";
import GlTag from "@/components/gl-tag.vue";
import { getLabelByDirection } from '@/utils/text-formatter'
export default {
  components: {
    GlTag,
    AddressesListModal,
    uploadBulkResultModal,
    GlExplainBlock,
    GlPriorityChip,
    GlLoader,
    GlCoinSelect,
    GlIcon,
    GlCheckbox,
    GlMenuItem,
    GlInput,
    vSelect
  },
  props: {
    isCreate: {
      type: Boolean,
      default: true,
    },
    alertId: {
      type: [String, Number],
      default: 0,
      require: true
    },
  },
  data() {
    return {
      createReportData: {},
      file: null,
      openUploadedModal: false,
      openAddressesListModal: false,
      loading: false,
      MAX_TRIGGERS: 5,
      MAX_EVENT_NAME_LENGTH: 120,
      MAX_DESCRIPTION_LENGTH: 200,
      editData: {},
      alertChanel: alertChanel,
      percentOfTxs: percentOfTxs,
      alertPriority: alertPriority,
      alertDirection: alertDirection,
      alertsTriggersList: alertsTriggersList,
      alertLogicOperators: alertLogicOperators,
      quantityPropsList: quantityPropsList,
      ownersList: [],
      tagsList: [],
      typesList: [],
      defTriggerModel: {
        count: null,
        trigger: null,
        wallet: '',
        owner: [],
        edit: false,
        percentOfTxs: {
          label: 'More than',
          value: '$gt',
        },
        amount: null,
        amountCur: null,
        types: [],
        tags: [],
        quantityProp: 'btc',
        share: 0,
      },
      formData: {
        address: '',
        email: '',
        eventName: '',
        description: '',
        createProfileTag: false,
        retrospective: false,
        coin: null,
        direction: 0,
        logicOperator: '$anyOf',
        chanel: 'dashboard',
        priority: {
          label: 'Medium',
          value: 50
        },
        trigger: null,
        triggersList: [],
        tagsList: [],
        typesList: [],
        ownersList: [],
        triggerModel: {
          count: null,
          trigger: null,
          wallet: '',
          owner: [],
          edit: false,
          scoreFrom: '0',
          scoreTo: '100',
          percentOfTxs: {
            label: 'More than',
            value: '$gt',
          },
          amount: null,
          amountCur: null,
          types: [],
          tags: [],
          quantityProp: 'btc',
          share: 0,
        },
      },
    }
  },
  computed: {
    ...mapState('analytics', ['currencyList', 'coinData']),
    trackByLabel() {
      return this.getLabelByDirection(this.formData.direction.value)
    },
    shareInfo() {
      return 'Empty or 0% is any transaction containing condition. 1 - 100% is percent of tokens received from source chosen in conditions field.'
    },
    retrospectiveInfo() {
      return 'Use this to apply your alert conditions retrospectively, flagging any past transactions that would have triggered an alert.'
    },
    isTypesTagsTrigger() {
      return this.formData?.triggerModel?.trigger?.value === 'funds.id'
    },
    isOwnerTrigger() {
      return this.formData?.triggerModel?.trigger?.value === 'owner'
    },
    canSend() {
      const hasEmptyTagsTypes = this.formData?.triggersList?.map(el => el.trigger.value === 'funds.id' && !el?.tags?.length && !el?.types?.length).includes(true)
      const hasEmptyOwner = this.formData?.triggersList?.map(el => el.trigger.value === 'owner' && !el?.owner?.length).includes(true)
      const hasIncorrectScore = this.formData?.triggersList.find(el => Number(el.scoreFrom) > Number(el.scoreTo))

      const addressesForLinking = Array.isArray(this.formData.address) ? this.formData.address : [this.formData.address]

      return this.formData?.eventName.trim().length
        && this.formData?.eventName.trim().length <= this.MAX_EVENT_NAME_LENGTH
        && this.formData?.description.trim().length <= this.MAX_DESCRIPTION_LENGTH
        && this.formData?.triggersList?.length
        && this.formData.address
        && this.formData.address.length
        && !hasEmptyTagsTypes
        && !hasEmptyOwner
        && !hasIncorrectScore
        && addressesForLinking.length
    },
    isEdgeTriggersLength() {
      return this.formData.triggersList.length === this.MAX_TRIGGERS
    },
  },
  async mounted() {
    this.loading = true
    await this.getTypes({}).then(({ data }) => {
      this.typesList = data.typeData || []
    }).finally(() => {
      this.loading = false
    })
    await this.getTags({}).then(({ data }) => {
      this.tagsList = data.tagData || []
    }).finally(() => {
      this.loading = false
    })
    this.formData.triggerModel.quantityProp = this.quantityPropsList[0]
    if (this.alertId) {
      this.loading = true
      this.getAddressesForAlert(this.alertId).then((data) => {
        this.formData.address = data?.length ? data?.map(({ address }) => address) : null
        this.formData.coin = data?.length ? this.currencyList.find(el => el.key === data[0].coin) : this.coinData

        this.getAlertsById(this.alertId).then((data) => {
          this.editData = data

          this.formData.direction = this.alertDirection.find(el => el.value === this.editData.direction) || this.alertDirection[0]
          this.formData.description = this.editData.description || ''
          this.formData.logicOperator = this.alertLogicOperators.find(el => el.value === this.editData.logical)
          // eslint-disable-next-line no-prototype-builtins
          this.formData.priority = this.alertPriority.find(el => el.value === (this.editData.hasOwnProperty('priority') ? this.editData.priority : 50))
          this.formData.eventName = this.editData.title
          this.formData.retrospective = this.editData.retrospective
          this.formData.triggerModel.trigger = this.alertsTriggersList[0]
          this.formData.triggersList = this.editData.groupMatches.map(t => {
            return this.selectionEditDataByTrigger(t)
          })
        }).finally(() => {
          this.loading = false
        })
      })
    } else {
      this.formData.direction = this.alertDirection.find(el => el.value === this.formData.direction) || this.alertDirection[0]
      this.formData.logicOperator = this.alertLogicOperators.find(el => el.value === this.formData.logicOperator)
      this.formData.coin = this.currencyList.find(el => el.key === this.formData.coin) || this.coinData
      this.formData.triggerModel.trigger = this.alertsTriggersList[0]
    }
  },
  methods: {
    createAlertModel,
    createTriggerModel,
    createFilterModel,
    createConditionModel,
    getLabelByDirection,
    ...mapActions('alerts', ['createAlert', 'getAlertsById', 'updateAlert', 'getAddressesForAlert', 'bindAlertToAddresses']),
    ...mapActions('types', ['getTypes']),
    ...mapActions('tagging', ['getTags', 'getOwners']),
    async handleCoinChange(coin) {
      this.formData.coin = coin
      this.loading = true
      await this.getTypes({}).then(({ data }) => {
        this.typesList = data.typeData || []
      }).finally(() => {
        this.loading = false
      })
      await this.getTags({}).then(({ data }) => {
        this.tagsList = data.tagData || []
      }).finally(() => {
        this.loading = false
      })
    },
    handleCloseUploadedModal() {
      this.openUploadedModal = false
      this.$emit('close')
      this.$emit('success')
    },
    openFileInput() {
      console.log(this.$refs.addressAlertingUploader, 'sss')
      this.$refs.addressAlertingUploader.click()
    },
    processData(csvData) {
      const lines = csvData.split('\n');
      const results = [];

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i].split(',');
        const cleanedLine = line.map(value => value.replace(/["']/g, '').trim());
        results.push(cleanedLine);
      }

      this.formData.address = results.flat()
    },
    onFileChange(e) {
      if (this.loading) return

      if (e.target.files[0].type && !e.target.files[0].type.includes('csv')) {
        this.$toasted.global.error({ message: 'Invalid file type' })
        return;
      }

      this.file = e.target.files[0]

      const reader = new FileReader();

      reader.onload = (event) => {
        const csvData = event.target.result;
        this.processData(csvData);
        this.$refs.addressAlertingUploader.value = null;
      };

      reader.readAsText(this.file);
    },
    clearFile() {
      this.file = null
      this.formData.address = []
    },
    handleScoreTo(value, index) {
      if (index === undefined) {
        if (Number(value) > 100) {
          this.formData.triggerModel.scoreTo = 100
        }
      } else {
        if (this.formData.triggersList[index]) this.formData.triggersList[index].scoreTo = Number(value) > 100 ? 100 : Number(value)
      }
    },
    handleShare(value, index) {
      if (index === undefined) {
        if (Number(value) > 100) {
          this.formData.triggerModel.share = 100
        }
      } else {
        if (this.formData.triggersList[index]) this.formData.triggersList[index].share = Number(value) > 100 ? 100 : Number(value)
      }
    },
    searchByOwner(owner, loadingFn) {
      if (owner && owner.length >= 3) {
        loadingFn(true)
        this.getOwners({ owner }).then((data) => {
          this.ownersList = data.items || []
        }).catch(() => {
          this.ownersList = []
          loadingFn(false)
        }).finally(() => { loadingFn(false) })
      } else if (!owner) {
        loadingFn(true)
        this.ownersList = []
        loadingFn(false)
      }
    },
    addTrigger() {
      this.formData.triggersList.push(JSON.parse(JSON.stringify({ ...this.formData.triggerModel })))
      this.formData.triggerModel = {
        ...this.defTriggerModel,
        trigger: this.formData.triggerModel.trigger
      }
    },
    handleShortEdit(trigger, index) {
      this.formData.triggersList.splice(index, 1, { ...trigger, edit: Boolean(!trigger.edit) })
    },
    handleShortDelete(index) {
      this.formData.triggersList.splice(index, 1)
    },
    selectionFilter(t) {
      if (t.trigger.value === 'amountCur') {
        return []
      }else if (t.trigger.value === 'address') {
        return []
      } else if (t.trigger.value === 'funds.score') {
        return [this.createFilterModel(t.trigger.value, [this.createConditionModel(Number(t.scoreFrom), "$gte"), this.createConditionModel(Number(t.scoreTo), "$lte")])]
      } else if (t.trigger.value === 'funds.id') {
        const resFundsListTypeIds = Array.isArray(t.types) ? t.types.map(el => { if (el._id) return el._id }) : []
        const resFundsListTagIds = Array.isArray(t.tags) ? t.tags.map(el => { if (el._id) return el._id }) : []

        return [this.createFilterModel(t.trigger.value, [this.createConditionModel(resFundsListTypeIds.concat(resFundsListTagIds), "$in")])]
      } else if (t.trigger.value === 'owner') {
        const resOwnersList = Array.isArray(t.owner) ? t.owner.map(el => { return el.owner }) : []

        return [this.createFilterModel(t.trigger.value, [this.createConditionModel(resOwnersList, "$in")])]
      }
    },
    selectionEditDataByTrigger(t) {
      const amountPropIndex = t?.aggregate.findIndex(el => el.prop === 'amount')
      const amountCurPropIndex = t?.aggregate.findIndex(el => el.prop === 'amountCur')
      const scorePropIndex = t?.filter.findIndex(el => el.prop === 'funds.score')
      const fundsIdPropIndex = t?.filter.findIndex(el => el.prop === 'funds.id')
      const ownerPropIndex = t?.filter.findIndex(el => el.prop === 'owner')

      if (amountCurPropIndex !== -1 && !t?.filter?.length) {
        const formattedAmount = parseFloat(t?.aggregate[amountCurPropIndex]?.conditions[0].value)

        return {
          trigger: t?.aggregate[amountCurPropIndex].prop ? this.alertsTriggersList.find(el => el.value === 'amountCur') : null,
          amountCur: formattedAmount,
          percentOfTxs: this.percentOfTxs.find(q => {
            return q.value === t?.aggregate[amountCurPropIndex]?.conditions[0].op
          })
        }
      }else if (amountPropIndex !== -1 && !t?.filter?.length) {
        const formattedAmount = parseFloat(t?.aggregate[amountPropIndex]?.conditions[0].value) / Math.pow(10, this.formData?.coin?.decimals ?? 1).toFixed(this.formData?.coin?.decimals)

        return {
          trigger: t?.aggregate[amountPropIndex].prop ? this.alertsTriggersList.find(el => el.value === 'address') : null,
          amount: formattedAmount,
          percentOfTxs: this.percentOfTxs.find(q => {
            return q.value === t?.aggregate[amountPropIndex]?.conditions[0].op
          })
        }
      } else if (scorePropIndex !== -1) {
        return {
          trigger: t?.filter[0].prop ? this.alertsTriggersList.find(el => el.value === t?.filter[0].prop) : null,
          scoreFrom: t?.filter.find(el => el.prop === 'funds.score')?.conditions[0].value,
          scoreTo: t?.filter.find(el => el.prop === 'funds.score')?.conditions[1].value,
          share: Math.round(t?.aggregate.find(el => el.prop === 'share')?.conditions[0].value * 100),
          percentOfTxs: this.percentOfTxs.find(q => {
            return q.value === t?.aggregate.find(el => el.prop === 'share')?.conditions[0].op
          })
        }
      } else if (fundsIdPropIndex !== -1) {
        const conditions = t.filter.find(el => el.prop === 'funds.id').conditions

        if (conditions && conditions.length) {
          const resFundsListIds = conditions[0].value.map(el => { return el })

          return {
            trigger: t?.filter[0].prop ? this.alertsTriggersList.find(el => el.value === t?.filter[0].prop) : null,
            tags: this.tagsList.filter(el => resFundsListIds.includes(el._id)),
            types: this.typesList.filter(el => resFundsListIds.includes(el._id)),
            share: Math.round(t?.aggregate.find(el => el.prop === 'share')?.conditions[0].value * 100),
            percentOfTxs: this.percentOfTxs.find(q => {
              return q.value === t?.aggregate.find(el => el.prop === 'share')?.conditions[0].op
            })
          }
        }
      } else if (ownerPropIndex !== -1) {
        const owners = t.filter.find(el => el.prop === 'owner').conditions

        if (owners && owners.length) {
          const resOwnersList = owners[0].value.map(el => { return { owner: el } })

          return {
            trigger: t?.filter[0].prop ? this.alertsTriggersList.find(el => el.value === t?.filter[0].prop) : null,
            owner: resOwnersList,
            share: Math.round(t?.aggregate.find(el => el.prop === 'share')?.conditions[0].value * 100),
            percentOfTxs: this.percentOfTxs.find(q => {
              return q.value === t?.aggregate.find(el => el.prop === 'share')?.conditions[0].op
            })
          }
        }
      }
    },
    createAggregateByShare(t) {
      const localData = {
        share: t?.share,
        percentOfTxs: t?.percentOfTxs?.value
      }

      if (t.trigger.value === 'address') {
        return  [{
          "prop": "amount",
          "agg": "$sum",
          "conditions": [
            {
              "op": `${t.percentOfTxs.value}`,
              "value": currencyShareFormatting(Number(t.amount), this.formData.coin.decimals) || 0
            }
          ]
        }]
      }

      if (t.trigger.value === 'amountCur') {
        return  [{
          "prop": "amountCur",
          "agg": "$sum",
          "conditions": [
            {
              "op": `${t.percentOfTxs.value}`,
              "value": Number(t.amountCur) || 0
            }
          ]
        }]
      }

      return t.trigger.value === 'funds.score'
      || t.trigger.value === 'funds.id'
      || t.trigger.value === 'owner' ? [
        // {
        //   "prop": "amount",
        //   "agg": "$sum",
        //   "as": "total",
        //   "filter": false
        // },
        // {
        //   "prop": "amount",
        //   "agg": "$sum"
        // },
        {
          "prop": "share",
          "agg": "$sum",
          // "expr": {
          //   "op": "$divide",
          //   "args": ["amount", "total"]
          // },
          ...this.createFilterModel('share', [this.createConditionModel(Number(localData.share) / 100, `${localData.percentOfTxs}`)])
        }
      ] : []
    },
    handleSubmit() {
      this.loading = true
      const newAlert = {
        priority: Number.isInteger(this.formData.priority.value) ? this.formData.priority.value : 50,
        description: this.formData.description || '',
        direction: this.formData?.direction?.value || 0,
        retrospective: this.formData.retrospective || false,
        ...this.createAlertModel(
          ['directTx'],
          this.formData.logicOperator.value,
          this.formData.triggersList.map((t) => this.createTriggerModel(
            "$allOf",
            this.selectionFilter(t),
            // [{agg: "$count", prop: "directTx", as: "count"}, {agg: "$max", prop: "timestamp", as: 'lastTriggered'}].concat(this.createAggregateByShare(t))
            this.createAggregateByShare(t)
          ))
        ),
        // aggregate: [{agg: "$count", prop: "directTx", as: "count"}, {agg: "$max", prop: "timestamp", as: 'lastTriggered'}]
      }

      const addresses = Array.isArray(this.formData.address) ? this.formData.address.filter(address => new RegExp(this.coinData.addressRegex).test(address)) : [this.formData.address]

      this.createReportData = {}

      if (this.isCreate) {
        this.createReportData.invalid = this.formData.address.reduce((acc, curr) => {
            return !new RegExp(this.coinData.addressRegex).test(curr) ? acc + 1 : acc;
          }, 0)

        if (this.formData.address.length === this.createReportData.invalid) {
          this.$toasted.global.error({ message: 'Can not to create an alert because all addresses are not valid. Update your file.' })
          this.loading = false
          return
        }

        this.createAlert({ ...newAlert, title: this.formData.eventName }).then((data) => {
          const creatingData = data
            this.bindAlertToAddresses({ id: creatingData.id, addresses, alertId: creatingData.id, coin: this.coinData.key }).then((data) => {
              this.$toasted.global.success({ message: 'Success' })

              this.createReportData = {
                count: data.length || 0,
                invalid: (this.formData.address.length - data.length) || 0
              }

              this.openUploadedModal = true
            }).catch(() => {
              this.$toasted.global.error({ message: 'Request error' })
            }).finally(() => {
              this.loading = false
            })
        })
      } else {
        this.updateAlert({ id: this.editData.id, data: { ...newAlert, title: this.formData.eventName } }).then(() => {
          this.bindAlertToAddresses({ id: this.editData.id, addresses, alertId: this.editData.id, coin: this.coinData.key }).then(() => {
            this.$emit('close')
            this.$emit('success')
          }).catch(() => {
            this.$toasted.global.error({ message: 'Request error' })
          }).finally(() => {
            this.loading = false
          })
          this.$toasted.global.success({ message: 'Success' })
          this.$emit('close')
          this.$emit('success')
        }).catch(() => {
          this.$toasted.global.error({ message: 'Request error' })
        }).finally(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>

<style>
.alert-section-list__item {
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-template-rows: 1fr;
  grid-column-gap: 3px;
  grid-row-gap: 0;
}

.short-large-grid {
  display: grid;
  grid-template-columns: minmax(260px, 1fr) 3.6fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0;
}

.medium-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0;
}

.large-short-grid {
  display: grid;
  grid-template-columns: 3.6fr 1.2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0;
}

.alert-key {
  display: flex;
  font-weight: 500;
  color: var(--dark-grey-6-e);
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.alert-section-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.alert-select-more {
  height: fit-content;
  width: 235px;
}

.alert-select-priority,
.alert-select-chanel {
  height: fit-content;
  width: 250px;
}

.alert-section-list__trigger-item {
  background-color: var(--pale-blue);
  padding: 13px 16px;
  align-items: center;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.alert-section-list__trigger-item--edit {
  background-color: var(--pale-yellow);
}

.vs__search {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px !important;
}

.alert-modal-loading {
  opacity: 0.4;
  pointer-events: none;
}

.alert-modal-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
</style>
